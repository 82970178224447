import React from "react"
import Layout from "../../layouts/de"
import WirePageBase from "../../templates/wire"
import Intro from "../../components/intro"
import Link from "../../components/link"
import Cover from "../../components/cover"
import Illustration from "../../components/illustration"

export default () => {
    return (
        <Layout>
            <WirePageBase locale="de">
                <Intro title="Hochleistungs-Draht made by Finkernagel">
                    <p>Unsere innovative Drahtproduktion und die Vielzahl unserer möglichen Lieferformen machen aus Finkernagel ein Hochleistungs-Drahtwerk. Unser Draht hält den anspruchsvollsten Umformungen stand. Die umfangreichen Prüfeinrichtungen, wie beispielsweise die Drahtoberflächenprüfung, garantieren höchste Qualität. Als Problemlöser und Projektbegleiter nehmen wir unsere Aufgabe ernst und sorgen mit unserer Just-in-time-Logistik dafür, jeden Kundenwunsch schnellstmöglich zu erfüllen.</p>
                    <Link to="/wire/delivery-program" className="accessory">Zum Lieferprogramm</Link>
                </Intro>
                <Cover eyebrow="Walzdrahtlager" title="Qualität von Anfang an">
                    <p>Wir beziehen unseren Walzdraht von qualifizierten Stahl- und Walzwerken. Ein gut sortiertes Vormateriallager auf mehr als 5.000 m² Lagerfläche sichert eine hohe Lieferfähigkeit. Der Walzdraht wird schonend in vorgegebenen Bereichen gelagert.</p>
                    <p>Unsere effektive Materialwirtschaft und das sorgfältige Handling bei der Ein- und Auslagerung stellen eine einwandfreie Beschaffenheit bei Materialeinsatz sicher.</p>
                </Cover>
                <Cover eyebrow="Beiz- und Beschichtungsanlage" title="Leistungsfähige Oberflächen in großer Vielfalt" align="right">
                    <p>In unseren 18 großvolumigen Behandlungsbädern wird das Material vollautomatisch gereinigt und beschichtet. Verbrauchte Medien werden automatisch bedarfsbezogen ergänzt. Somit ergibt sich ein konstantes und reproduzierbares Behandlungsergebnis.</p>
                    <p>Unsere umfassende Prozessvisualisierung sorgt für eine lückenlose Dokumentation und Rückverfolgbarkeit des gesamten Prozessverlaufes.</p>
                </Cover>
                <Cover eyebrow="Drahtzug" title="Unsere Ziehanlagen als Basis unserer Qualität">
                    <p>Auf 16 SPS-gesteuerten Ziehanlagen produzieren wir Draht in engsten Toleranzen im Abmessungsbereich von Ø 1,00 mm bis 33,00 mm.</p>
                    <p>Alle Ziehmaschinen sind in unserem MDE-/BDE-System eingebunden, so dass die aktuellen Fertigungssituationen bewertet, Abweichungen sofort analysiert und mit geeigneten Maßnahmen beseitigt werden können. Die wesentlichen Prozessdaten sind so für jeden einzelnen Ring reproduzierbar.</p>
                </Cover>
                <Cover eyebrow="Wärmebehandlung" title="Optimales Glühgefüge für ein ausgezeichnetes Umformergebnis" align="right">
                    <p>In unseren Haubenofenanlagen wird auf vier Sockeln Walzdraht und vorgezogener Draht mit Chargengrößen bis zu 50 Tonnen programmgesteuert geglüht. Durch den Einsatz von Wasserstoff als Schutzgas ergeben sich sauberste Drahtoberflächen, ein homogenes Glühgefüge und gleichmäßige mechanische Eigenschaften.</p>
                </Cover>
                <Cover eyebrow="Qualitätsmanagement" title="Umsetzung von anspruchsvollen Forderungen">
                    <p>Eine kontinuierliche <strong>DR</strong>aht-<strong>O</strong>berflächen-<strong>P</strong>rüfung (DROP) erlaubt Rückschlüsse auf die eigene und die vormaterialbezogene Prozessstabilität.</p>

                    <p>Wir sind gemäß EN ISO 9001 und IATF 16949 zertifiziert.</p>

                    <Link to="/downloads">Zertifikate laden</Link>
                </Cover>
                <Cover eyebrow="Logistik" title="Jederzeit bereit für Ihre Produktion – Just-in-time" align="right">
                    <p>In  unserer Logistik werden die produzierten Drähte gemäß Kundenwunsch verpackt, gelagert und termingerecht ausgeliefert.</p>

                    <p>Das Materialhandling ist darauf ausgelegt, den Draht so schonend wie möglich zu behandeln und sicher zu versenden. Quer-, Längs- oder Containerverladungen werden unter Dach ausgeführt. Lieferdokumente werden auf Wunsch elektronisch zur Verfügung gestellt.</p>
                </Cover>

                <section className="section">
                    <header className="container">
                        <div className="col md:ml-auto md:mr-0 md:w-1/2 lg:w-1/3">
                            <h3 className="section-heading">Der Produktionsprozess im Überblick</h3>
                        </div>
                    </header>
                    <Illustration imageName="beschichten">
                        <h2 className="text-black">Beizen und Beschichten</h2>
                        <p>Der Draht wird vom Zunder befreit, gereinigt und beschichtet.</p>
                    </Illustration>
                    <Illustration imageName="drahtziehen">
                        <h2 className="text-black">Drahtziehen</h2>
                        <p>Der beschichtete Draht wird durch einen oder mehrere Ziehsteine an einen dünneren Durchmesser gezogen.</p>
                    </Illustration>
                    <Illustration imageName="gluehen">
                        <h2 className="text-black">Glühen</h2>
                        <p>Bei Temperaturen bis zu 800 °C wird der durch das Ziehen verfestigte Draht wieder weich und für die weitere Verarbeitung gut umformbar.</p>
                    </Illustration>
                </section>
            </WirePageBase>
        </Layout>
    )
}
